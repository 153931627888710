import React,{useState,useEffect,useRef}  from "react";
import Layout from "../components/layout";
import FormGenerator from "../components/form/FormGenerator";
import List from "../components/form/List";
import styled from "styled-components";
import Edit from "../components/form/Edit";
import {BaseSelect,BaseFormField,FormSubmit} from "../styles/Global"
import { GetList } from "../utils/ApiFunctions";
import axios from "axios";
import mainjson from '../config/main';


const Header = styled.div`
color: #212529;
text-align: left;
font-size: 14px;
font-weight: 300;
box-sizing: border-box;
outline: 0;
display: flex;
flex-wrap: wrap;
background: #fff;
padding: 14px 10px;
box-shadow: 1px 0 20px rgba(0,0,0,.08);
`

const Title = styled.div`
    text-align: left;
    box-sizing: border-box;
    outline: 0;
    font-family: Poppins,sans-serif;
    font-weight: 300;
    line-height: 1.2;
    color: inherit;
    font-size: 1.125rem;
    margin-bottom: 0;
    margin-top: 0;
    margin-left:10px;
`
const Right = styled.div`
position:absolute;
right:25px;
`

const ListContainer = styled.div`
  padding-bottom:20px;
`;

const ListTitle = styled.div`
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-size: 14px;
  box-sizing: border-box;
  outline: 0;
  display: inline-block;
  margin-bottom: .5rem;
  font-weight: 400;
`;

const ListTitleBlock = styled.div`
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-size: 12px;
  box-sizing: border-box;
  outline: 0;
  display: inline-block;
  margin-bottom: 1rem;
  font-weight: 400;
`;


const InputContainer = styled.div`
 
`;

const Select = styled(BaseSelect)`

`

const Top = styled.div`
padding:25px;

`

const Container = styled.div`
padding:25px;
background-color:white;
`



export default function Page() {
    const [options, setOptions] = useState([]);
    const [zip, setZip] = useState("");
     const [zipContent, setZipContent] = useState([]);
    const input = useRef();
    const inputRef = useRef();
    const [value, setValue] = useState(0);
    const [fileName, setFileName] = useState("");
    const [imported, setImported] = useState(false);
    const [importing, setImporting] = useState(false);
    const [importasvalue, setimportasValue] = useState(0);
    const filenameRef = useRef("");
    const [active, setActive] = useState(true);
    const maxmb = 25;

    function changed(e){
        setValue(e.target.value);
        checkActive();
    }

    function importaschanged(e){
        setimportasValue(e.target.value);
        console.log(e.target.value);
    }

    async function parseFile() {
        
        var file = inputRef.current.files[0];
        var done = false;

        if (!file) {
            setZip(null);
            return;
        }


        filenameRef.current = file.name;
        let mb = file.size / 1000000;
        if (mb > maxmb) {
            alert("file to big, max upload size of zip is 25mb");
            inputRef.current.value = null;
        }
        else {
            // var reader = new FileReader();
            // reader.readAsBinaryString(file);
            // reader.onload = function() {
            //      setZipContent(reader.result);
            //  };
             setZip(file);
        }

        //console.log("na hoi");
        
        //filenameRef.current = file.name;
        // //setCurrentFile(file);
        // console.log(file);
      
         //reader.onerror = function() {
        // console.log('there are some problems');
        // };
    }
  

    function fileChanged(e){ 
        parseFile();
    }

    function checkActive(){
        console.log("checkactive ",value,imported);
    }

    async function submit(){
        console.log("submit");
         setImported(false)
        let resultdata = "";
        setImporting(true);

        
        let body = {
            "type":"import",
            "CMSUser-Agent":navigator.userAgent,
        }

        if (localStorage) {
            body["CMSUser-Token"] = localStorage.getItem('token');
        }

        var formData = new FormData();
        formData.append("file", zip);
        formData.append("body", JSON.stringify(body));
    
        await axios({
            method: "post",
            url: mainjson().uploadfiles_path,
            data: formData,
            header: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            },
          })
        .then((response) => {
           if (response.data) {
               if (response.data.error != "" && response.data.error != null) {
                    alert("Not imported: "+response.data.error);
                    inputRef.current.value = null;
                }
                else {
                    setFileName(response.data.file)
                    inputRef.current.value = null;
                    setImported(true);
                    setZip(null);
                }
               
               setImporting(false);
           }
        })
        .catch((error) => {
            console.log("error "+error);
            setImporting(false);
        });
    
        return "";
    }

    useEffect(() => {
        if (zip && !importing) {
            setActive(true);   
            console.log("use effect set active true");
        }
        else {
            setActive(false);
            console.log("use effect set active false",zip,filenameRef.current,importing);
        }
    }, [zip, value,imported,importing]);

    

  return (
    <Layout>
        <Header>
            <Title>
                   Upload files
            </Title>

        </Header>
        <Top>
        <Container>
               
            <ListContainer>
                <ListTitle>File</ListTitle>
                <BaseFormField type='file' ref={inputRef} onChange={fileChanged} onInput={fileChanged}  accept="*"/>
            </ListContainer>
            <ListContainer>
                {imported && <p>Imported {filenameRef.current}<br/>{fileName}</p>

                }
                {zip && active &&
                <FormSubmit onClick={submit}>Import</FormSubmit>
                }
                {importing && " Importing, please wait. (this can take a while)"

                }
                </ListContainer>
            
        
            </Container>
            </Top>
    </Layout>
  );
}